// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application";
import FlashController from "./flash_controller";
import HelpItemFormController from "./help_item_form_controller";
import ModalBarController from "./modal_bar_controller";
import NoUrlChangeController from "./no_url_change_controller";
import RuleFormController from "./rule_form_controller";
import RulesetPickerController from "./ruleset_picker_controller";
import RulesetFormController from "./ruleset_form_controller";
import SidebarController from "./sidebar_controller";
import BreathingExerciseController from "./breathing_exercise_controller";
import TabsController from "./tabs_controller";
import NudgeFormController from "./nudge_form_controller";
import PaginationController from "./pagination_controller";
import SearchController from "./search_controller";
import PreviewModalController from "./preview_modal_controller";
import MediaLibraryController from "./media_library_controller";
import DatatableController from "./datatable_controller";

application.register("breathing-exercise", BreathingExerciseController);
application.register("no-url-change", NoUrlChangeController);
application.register("flash", FlashController);
application.register("help-item-form", HelpItemFormController);
application.register("modal-bar", ModalBarController);
application.register("rule-form", RuleFormController);
application.register("ruleset-form", RulesetFormController);
application.register("ruleset-picker", RulesetPickerController);
application.register("sidebar", SidebarController);
application.register("tabs", TabsController);
application.register("nudge-form", NudgeFormController);
application.register("pagination", PaginationController);
application.register("search", SearchController);
application.register("preview-modal", PreviewModalController);
application.register("media-library", MediaLibraryController);
application.register("datatable", DatatableController);
