import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "datatables.net";
// import 'datatables.net-dt/css/dataTables.dataTables.min.css';

export default class extends Controller {
  static values = {
    source: String, // URL for server-side data
    columns: Array, // Array of column configurations
    order: Array, // Array to specify default ordering
  };

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const tableElement = $(this.element);

    // Destroy any existing DataTable instance before initializing a new one
    if ($.fn.dataTable.isDataTable(tableElement)) {
      return
    }

    // Default configurations for DataTable
    const defaultConfig = {
      ajax: this.sourceValue, // Server-side URL
      serverSide: true,
      processing: true,
      columns: this.columnsValue, // Dynamically passed columns
      order: this.orderValue || [[0, 'asc']],
      lengthMenu: [10, 25, 50, 100, 200, 500, 999],
      pageLength: 100,
      rowCallback: (row, data) => {
        // Assume 'id' is a property in the server response data for each row
        if (data.id) {
          $(row).attr('id', data.dom_id);
        }
      },
    };

    // Initialize DataTable with dynamic configurations
    tableElement.DataTable(defaultConfig);
  }
}
